import React, { FC } from 'react';
import { ExtendableImage } from '@design-system/extendable-image';
import { Typography } from '@design-system/typography';

import { IProductHeaderProps } from './models';

import './ProductHeader.scss';

const ProductHeader: FC<IProductHeaderProps> = ({ title, description, backgroundImage }) => (
  <div className="product-header">
    <div className="cds-container cds-container--max-grid">
      <ExtendableImage
        className="product-header__background-image"
        props={{ src: backgroundImage.url }}
      />
    </div>
    <div className="cds-container cds-container--max-grid cds-page-block__content product-header__wrapper">
      {title && (
        <Typography
          className="product-header__title"
          tag="h1"
          variant="headline"
          size="xxxl"
          content={title}
          align="start"
          weight="bold"
          color="#262861"
        />
      )}
      <Typography
        className="product-header__description"
        tag="div"
        variant="body"
        size="l"
        content={description}
        align="start"
        weight="regular"
      />
    </div>
  </div>
);

export default ProductHeader;
