import React, { FC } from 'react';
import { Typography } from '@design-system/typography';

import { ISectionTitle } from './models';

import './SectionTitle.scss';

const SectionTitle: FC<ISectionTitle> = ({ title }) => (
  <div className="section-title">
    <div className="cds-container cds-container--max-grid cds-page-block__content  section-title">
      {title && (
        <Typography
          className="section-title__title"
          tag="h1"
          variant="headline"
          size="xxl"
          content={title}
          align="start"
          weight="bold"
          color="white"
        />
      )}
    </div>
  </div>
);

export default SectionTitle;
