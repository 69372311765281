import React from 'react';
import { CustomComponentsProvider } from '@husky-x/gatsby-theme-husky';

import CarouselImage from './src/components/CarouselImage';
import ProductHeader from './src/components/ProductHeader';
import SectionTitle from './src/components/SectionTitle';

export const wrapRootElement = ({ element }) => (
  <CustomComponentsProvider
    list={[
      {
        key: 'productHeader',
        component: ProductHeader,
      },
      {
        key: 'itemListingAerogard',
        component: SectionTitle,
      },
      {
        key: 'carouselImage',
        component: CarouselImage,
      },
      {
        key: 'sectionTitle',
        component: SectionTitle,
      },
    ]}
  >
    {element}
  </CustomComponentsProvider>
);
