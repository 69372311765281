import React, { FC } from 'react';
import { ExtendableImage } from '@design-system/extendable-image';

import { ICarouselImage } from './models';

import './CarouselImage.scss';

const CarouselImage: FC<ICarouselImage> = ({ backgroundImage }) => (
  <div className="product-header">
    <ExtendableImage
      className="product-header__background-image"
      props={{ src: backgroundImage.url }}
    />
  </div>
);

export default CarouselImage;
